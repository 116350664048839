import { Typography, styled } from '@mui/material';

const Title = styled(Typography)({
  flexGrow: 1,
  margin: '24px',
  color: 'rgba(0,0,0,0.87)',
  fontSize: 20,
  fontFamily: 'Roboto',
  fontWeight: 500,
  lineHeight: '30px',
  letterSpacing: 0.15,
  display: { xs: 'none', sm: 'block' },
});

export default Title;
