import useMutate from './useMutate';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useIntegrationUpdate = (flowInstanceId, flowInstanceIntegrationId) => {
  const url = `${REACT_APP_INSIGHTS_APP_BFF_API_URL}/flow_instances/${flowInstanceId}/integrations/${flowInstanceIntegrationId}`;

  const { trigger, inProgress, error } = useMutate(url, 'PATCH');

  return { trigger, inProgress, error };
};

export default useIntegrationUpdate;
