import useSWR from 'swr';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useUserInteractions = incidentId => {
  const url =
    REACT_APP_INSIGHTS_APP_BFF_API_URL + `/insights/${incidentId}/user_interactions/ai_suggestions`;

  const { data, error, isLoading, mutate } = useSWR(url);

  return {
    interactions: data,
    error,
    isLoading,
    mutate,
  };
};

export default useUserInteractions;
