import { Card } from '@mui/material';

const cardStyles = {
  p: 2,
  borderWidth: {
    xs: 0,
    md: '1px',
  },
  borderRadius: {
    xs: 0,
    md: '4px',
  },
};

export default function Widget({ children, ...props }) {
  return (
    <Card variant="outlined" sx={cardStyles} {...props}>
      {children}
    </Card>
  );
}
