import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import { displayValue } from '../../utils/display';

const splitText = (text, threshold) => {
  const words = text.split(' ');
  const firstHalf = words.slice(0, threshold).join(' ');
  const secondHalf = words.slice(threshold).join(' ');
  return `${firstHalf}<br>${secondHalf}`;
};

const xAxisLabelsMap = {
  itemsviewed: 'Product<br>Views',
  itemsaddedtocart: 'Carts',
  itemscheckedout: 'Checkouts',
  itemspurchased: 'Orders',
  'itemrevenue/itemavgvalue': 'AOV',
};

export default function WaterfallChart({ title, currency, width, height, funnelMetrics }) {
  const productsRevenue = funnelMetrics[funnelMetrics.length - 1];
  const revenueImpacts = funnelMetrics.slice(0, funnelMetrics.length - 1);

  // get window width for responsive chart
  const [_winWidth, setWinWidth] = useState(window.innerWidth);
  const plotRef = useRef(null);
  const [_plotHeight, setPlotHeight] = useState(0);
  const [plotWidth, setPlotWidth] = useState(0);
  useEffect(() => {
    if (plotRef.current) {
      setPlotHeight(plotRef.current.offsetHeight);
      setPlotWidth(plotRef.current.offsetWidth);
    }
  });

  useEffect(() => {
    const handleResize = () => setWinWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const xAxisLabels = [
    'Revenue<br>(baseline)',
    ...revenueImpacts.map(x => xAxisLabelsMap[x.id]),
    'Revenue<br>(actual)',
  ];

  const measure = ['absolute']
    .concat(Array(xAxisLabels.length - 2).fill('relative'))
    .concat('total');

  const yAxisValues = [
    productsRevenue.baselineValue,
    ...revenueImpacts.map(x => x.keyMetricImpact),
    productsRevenue.actualValue,
  ];

  // responsive properties
  const bottomMargin = plotWidth > 500 ? 75 : 100;
  const lrMargin = plotWidth > 500 ? 60 : 0;
  const xTickAngle = plotWidth > 500 ? 0 : 80;

  const titleThreshold = Math.round(title.split(' ').length / 2);
  title = plotWidth > 500 ? title : splitText(title, titleThreshold);

  const subtitleThreshold = Math.round(title.split(' ').length / 2);
  let subtitle = 'Incremental contribution of revenue funnel steps';
  subtitle = plotWidth > 355 ? subtitle : splitText(subtitle, subtitleThreshold);
  let subtitleY = plotWidth > 500 ? 1.1 : 1.05;

  {
    // compute yaxis range, from (min) to (max+10%) of accumulated values
    const accValues = yAxisValues
      .slice(0, -1)
      .reduce((acc, v) => [...acc, acc[acc.length - 1] + v], [0]);

    const maxAccValue = Math.max(...accValues);
    const minAccValue = Math.min(...accValues);
    const minAccValueExclZero = Math.min(...accValues.splice(1));
    const range = maxAccValue - minAccValue;
    var yaxisRange = [
      Math.min(minAccValue, minAccValueExclZero - range * 0.12),
      maxAccValue + range * 0.15,
    ];
  }

  {
    // compute text to display on each bar (e.g. +$23,000)
    const valueSign = v => (v < 0 ? '-' : '+');

    var barLabels = yAxisValues.map(
      (v, i) =>
        (i === 0 || i === yAxisValues.length - 1 ? '' : valueSign(v)) +
        currency +
        Math.abs(v).toLocaleString('en-US', { maximumFractionDigits: 0 }),
    );
  }

  {
    // generate tooltips
    const template = (val, baseline, incrImp, prefix) =>
      `Value: ${(prefix || '') + displayValue(val)}<br>Baseline: ${(prefix || '') + displayValue(baseline)}<br>Incremental impact: ${incrImp}`;

    const impactTooltips = funnelMetrics
      .slice(0, funnelMetrics.length - 1)
      .map((x, i) =>
        template(
          x.actualValue,
          x.baselineValue,
          barLabels[i + 1],
          x.name === 'Average products value' && currency,
        ),
      );

    var tooltipsContent = [
      `Value: ${barLabels[0]}`,
      ...impactTooltips,
      `Value: ${barLabels[barLabels.length - 1]}`,
    ];
  }

  const data = [
    {
      name: 'Revenue Insights',
      type: 'waterfall',
      orientation: 'v',
      measure,
      textposition: 'outside',
      x: xAxisLabels,
      text: barLabels,
      y: yAxisValues,
      connector: {
        line: {
          color: 'gray',
          width: 1,
        },
      },
      decreasing: { marker: { color: '#9B1515' } },
      increasing: { marker: { color: '#01973F' } },
      totals: { marker: { color: '#C2C7CF' } },
      xhoverformat: '$,.0f',
      yhoverformat: '$,.0f',
      customdata: tooltipsContent,
      hovertemplate: '%{customdata}<extra></extra>',
    },
  ];

  const layout = {
    dragmode: false,
    margin: {
      b: bottomMargin,
      l: lrMargin,
      r: lrMargin,
    },
    title: {
      text: title,
      font: {
        family: 'Roboto',
        size: 22,
      },
    },
    xaxis: {
      type: 'category',
      tickangle: xTickAngle,
    },
    yaxis: {
      type: 'linear',
      showticklabels: false,
      range: yaxisRange,
    },
    autosize: true,
    showlegend: false,
    font: {
      family: 'Roboto',
      size: 12,
      color: 'dimgray',
    },
    annotations: [
      {
        xref: 'paper',
        yref: 'paper',
        y: subtitleY,
        xanchor: 'center',
        yanchor: 'top',
        text: subtitle,
        showarrow: false,
        font: {
          family: 'Roboto',
          size: 14,
        },
      },
    ],
  };

  const config = {
    responsive: true,
    fillFrame: false,
    scrollZoom: false,
    displaylogo: false,
    displayModeBar: false,
  };

  return (
    <div ref={plotRef}>
      <Plot
        className="waterfallChart"
        data={data}
        layout={layout}
        config={config}
        style={{ width, height }}
      />
    </div>
  );
}
