import React from 'react';
import useVideoPlayer from '../../../../hooks/useVideoPlayer';
import { Box, Button, Modal, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import 'video.js/dist/video-js.css';

const VideoModal = ({ isOpen, onClose, videoSrc }) => {
  const { videoElementRef, handleClose, error } = useVideoPlayer(isOpen, onClose, videoSrc);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      role="dialog"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '80%', md: '70%', lg: '60%' },
          maxWidth: '800px',
          boxShadow: 24,
          padding: { xs: '20px', sm: '30px' },
          bgcolor: '#fafafa',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            display: { xs: 'block', md: 'none' },
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>

        {!error ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <video
              ref={videoElementRef}
              className="video-js vjs-big-play-centered"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
        ) : (
          <div> Video Loading Error!</div>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', mt: 2 }}>
          <Button variant="text" size="small" color="secondary" onClick={handleClose}>
            CLOSE
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default VideoModal;
