import { useState } from 'react';
import _ from 'lodash';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  TextField,
} from '@mui/material';
import MetadataForm from './MetadataForm';

const iconSx = {
  height: '30px',
  width: '30px',
  objectFit: 'contain',
};

function IntegrationForm({
  connectors,
  flowInstanceIntegration,
  updateMetadata,
  updateConnector,
  updateErrors,
}) {
  const initialize_connector_id = flowInstanceIntegration => {
    return flowInstanceIntegration ? flowInstanceIntegration.connector?.id : connectors[0]?.id;
  };

  const initialize_connector = connector_id => {
    return connectors.find(connector => connector.id === connector_id);
  };

  const initialize_metadata = schema => {
    if (flowInstanceIntegration) {
      return schema
        ? flowInstanceIntegration.connector_metadata
        : JSON.stringify(flowInstanceIntegration.connector_metadata, null, 2);
    }

    return schema ? {} : '';
  };

  const [errorMetadata, setErrorMetadata] = useState([]);
  const [connectorId, setConnectorId] = useState(initialize_connector_id(flowInstanceIntegration));
  const [selectedConnector, setSelectedConnector] = useState(initialize_connector(connectorId));
  const [hasSchema, setHasSchema] = useState(!_.isEmpty(selectedConnector?.config));
  const [metadata, setMetadata] = useState(initialize_metadata(hasSchema));

  const updateForm = connector_id => {
    const connector = initialize_connector(connector_id);
    const schema = !_.isEmpty(connector?.config);
    const metadata_ = initialize_metadata(schema);

    updateMetadata(metadata_);
    setMetadata(metadata_);
    setSelectedConnector(connector);
    setHasSchema(schema);
  };

  const handleConnectorChange = event => {
    setConnectorId(event.target.value);
    updateForm(event.target.value);
    updateConnector(event.target.value);
  };

  const handleJSONMetadataChange = event => {
    const newText = event.target.value;
    setMetadata(newText);
    try {
      updateMetadata(JSON.parse(newText));
      setErrorMetadata([]);
      updateErrors([]);
    } catch (_e) {
      setErrorMetadata(['Invalid JSON']);
      updateErrors(['Invalid JSON']);
    }
  };

  const handleFormMetadataChange = event => {
    setMetadata(event.formData);
    setErrorMetadata(event.errors);
    updateMetadata(event.formData);
    updateErrors(event.errors);
  };

  return (
    <>
      {connectors.length > 0 && (
        <FormControl sx={{ mt: 2, mb: 2 }} fullWidth>
          <InputLabel id="connector-label">Connector</InputLabel>
          <Select
            name="connector_id"
            labelId="connector-label"
            id="connector-select"
            label="Connector"
            value={connectorId}
            onChange={handleConnectorChange}
            sx={{ '& #connector-select': { display: 'inline-flex' } }}
          >
            {connectors.map(connector => (
              <MenuItem value={connector.id} key={connector.id}>
                <ListItemIcon>
                  <Box component="img" sx={iconSx} src={connector.icon} />
                </ListItemIcon>
                <ListItemText>{connector.name}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {hasSchema ? (
        <MetadataForm
          connector={selectedConnector}
          connector_metadata={metadata}
          onChange={handleFormMetadataChange}
        />
      ) : (
        <TextField
          multiline
          fullWidth
          label="Connector metadata"
          value={metadata}
          onChange={handleJSONMetadataChange}
          helperText={errorMetadata[0]}
          error={errorMetadata.length > 0}
          sx={{ minWidth: '350px' }}
        />
      )}
    </>
  );
}

export default IntegrationForm;
