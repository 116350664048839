import useSWR from 'swr';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useInsight = insightId => {
  const url = REACT_APP_INSIGHTS_APP_BFF_API_URL + `/api/v1/insights/${insightId}`;

  const { data, error, isLoading, mutate } = useSWR(url, { shouldRetryOnError: false });

  return {
    insight: data,
    error,
    isLoading,
    mutate,
  };
};

export default useInsight;
