import useSWR from 'swr';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useFlows = () => {
  const url = REACT_APP_INSIGHTS_APP_BFF_API_URL + `/flows`;

  const { data, error, isLoading } = useSWR(url, {
    shouldRetryOnError: true,
  });

  return {
    flows: data,
    error,
    isLoading,
  };
};

export default useFlows;
