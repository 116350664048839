import {
  CheckCircleOutline,
  HelpOutline,
  HighlightOff,
  NotStartedOutlined,
  RemoveCircleOutline,
} from '@mui/icons-material';

export default function VerdictIcon({ verdict }) {
  switch (verdict) {
    case 'retained':
      return <CheckCircleOutline data-testid="retained-icon" />;
    case 'rejected':
      return <HighlightOff data-testid="rejected-icon" />;
    case 'inconclusive':
      return <HelpOutline data-testid="inconclusive-icon" />;
    case 'skipped':
      return <NotStartedOutlined data-testid="skipped-icon" />;
    case 'untested':
      return <RemoveCircleOutline data-testid="untested-icon" />;
    default:
      return null;
  }
}
