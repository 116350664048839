import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Slider,
  InputAdornment,
  TextField,
  styled,
  List,
  ListItemButton,
} from '@mui/material';
import { Search, Mic } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';

const inputBarStyle = {
  display: 'flex',
  gap: '35px',
  m: '24px',
  alignItems: 'flex-start',
  flexDirection: { xs: 'column', sm: 'row' },
};

const textCntStyle = {
  width: '100%',
  mr: '70px',
  '@media (max-width: 1300px)': {
    mr: '0px',
  },
};

const sensitivityStyle = { color: 'gray' };
const sliderCntStyle = {
  width: '300px',
  display: 'flex',
  '@media (max-width: 1200px)': {
    width: '200px',
  },
  '@media (max-width: 900px)': {
    width: '150px',
  },
  '@media (max-width: 600px)': {
    width: '100%',
  },
};

const ConversationStarters = styled(List)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  gap: '15px',
  padding: '0 0 0 0',
  marginTop: '15px',
  [theme.breakpoints.down('md')]: {
    gap: '10px',
    marginTop: '10px',
  },
}));

const ConversationStarter = styled(ListItemButton)(({ theme }) => ({
  flex: '1 1 calc(25% - 15px)',
  width: 'calc(25% - 20px)',
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.80)',
  borderRadius: '5px',
  minHeight: '80px',
  padding: '8px',
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.10)',
  },
}));

const sensitivityMapping = { low: 0, medium: 1, high: 2, 0: 'low', 1: 'medium', 2: 'high' };

function InputBar({ sensitivity, handleChangeSensitivity, setInsightsCount }) {
  const navigate = useNavigate();
  const urlQuery = useQuery();

  const searchBarDefaultValue = urlQuery.get('query') || '';
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(searchBarDefaultValue);
  }, [urlQuery]);

  const disabled = !handleChangeSensitivity;

  const [sensitivityDisplayValue, setSensitivityDisplayValue] = useState(
    sensitivityMapping[sensitivity],
  );

  const handleChangeSensitivityDisplayValue = (_e, newValue) => {
    setSensitivityDisplayValue(newValue);
  };
  const handleChangeSensitivityValue = (_e, newSensitivity) => {
    handleChangeSensitivity(_e, sensitivityMapping[newSensitivity]);
  };

  useEffect(() => {
    setSensitivityDisplayValue(sensitivityMapping[sensitivity]);
  }, [sensitivity]);

  const search = query => {
    const input = query.trim();
    if (!input) return;
    navigate(`/revenue-insights/search?query=${input}`);
    // hide pagination on new search request
    if (setInsightsCount) setInsightsCount();
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      const input = event.target.value.trim();
      search(input);
    }
  };

  const handleConversationStarter = query => {
    setInputValue(query);
    search(query);
  };

  const conversationStarters = [
    { id: 1, value: 'Show me summaries' },
    { id: 2, value: 'Show me this month’s most important incidents' },
    { id: 3, value: 'Find all incidents about the Email channel' },
    { id: 4, value: 'Filter recent UX-related incidents' },
  ];

  return (
    <Box sx={inputBarStyle} data-testid="input-bar">
      <Box sx={textCntStyle}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          placeholder="Type to search for incidents or click the examples below"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Mic />
              </InputAdornment>
            ),
          }}
          onKeyDown={handleKeyPress}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <ConversationStarters>
          {conversationStarters.map(({ id, value }) => (
            <ConversationStarter key={id} onClick={() => handleConversationStarter(value)}>
              {value}
            </ConversationStarter>
          ))}
        </ConversationStarters>
      </Box>
      <Box
        sx={{ alignItems: 'center', display: { xs: 'none', lg: 'flex' }, mt: '4px' }}
        alignItems="center"
      >
        <Typography variant="body1" sx={sensitivityStyle}>
          Sensitivity:
        </Typography>
      </Box>
      <Box sx={sliderCntStyle}>
        <Slider
          disabled={disabled}
          aria-label="Temperature"
          value={sensitivityDisplayValue}
          valueLabelDisplay="off"
          step={1}
          marks={[
            { value: 0, label: 'Low' },
            { value: 1, label: 'Medium' },
            { value: 2, label: 'High' },
          ]}
          min={0}
          max={2}
          onChange={handleChangeSensitivityDisplayValue}
          onChangeCommitted={handleChangeSensitivityValue}
          data-testid="sensitivity-toggle"
        />
      </Box>
    </Box>
  );
}

export default InputBar;
