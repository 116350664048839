import { useContext } from 'react';
import { State } from '../State';
import useSWR from 'swr';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useSearch = (query, page) => {
  const { state } = useContext(State);

  const url =
    REACT_APP_INSIGHTS_APP_BFF_API_URL +
    `/api/v1/organizations/${state.auth0OrgID}/insights/search?q=${query}&page=${page}`;

  const { data, error, isLoading } = useSWR(url, { shouldRetryOnError: false });

  return {
    insights: data?.insights,
    filteredCount: data?.filteredCount,
    error,
    isLoading,
  };
};

export default useSearch;
