import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    fontSize: 12,
    fontWeight: 'normal',
    padding: 12,
    letterSpacing: '0.3px',
    '.MuiTooltip-arrow::before': {
      color: theme.palette.common.white,
      border: '1px solid',
      borderColor: theme.palette.grey[400],
    },
    '.MuiTooltip-arrow': {
      width: '2em',
      height: '1em',
      marginTop: '-0.98em',
    },
  },
}));

export default LightTooltip;
