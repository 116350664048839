import { useContext } from 'react';
import useSWR from 'swr';
import { State } from '../State';
const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

function urlParamsStr(page, sensitivity) {
  let urlParams = [];
  urlParams.push(`page=${page}`);
  urlParams.push(`visible=true`);

  let sensitivityFilter;
  if (sensitivity === 'low') sensitivityFilter = 'sensitivity=low';
  if (sensitivity === 'medium') sensitivityFilter = 'sensitivity=low,medium';
  if (sensitivityFilter) urlParams.push(sensitivityFilter);

  return '?' + urlParams.join('&');
}

const useInsights = (page, sensitivity) => {
  const { state } = useContext(State);

  const url =
    '' +
    REACT_APP_INSIGHTS_APP_BFF_API_URL +
    `/api/v1/organizations/${state.auth0OrgID}/insights` +
    urlParamsStr(page, sensitivity);

  const { data, error, isLoading } = useSWR(url, { shouldRetryOnError: false });

  return {
    insights: data?.insights,
    filteredCount: data?.filteredCount,
    error,
    isLoading,
  };
};

export default useInsights;
