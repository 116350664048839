import { useState } from 'react';
import {
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import useFlows from '../../hooks/useFlows';
import useFlowInstanceCreate from '../../hooks/useFlowInstanceCreate';

const AddFlowInstanceButton = ({ mutateFlowInstances }) => {
  const { flows, _loading, error } = useFlows();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [flowID, setFlowID] = useState();
  const { trigger, inProgress } = useFlowInstanceCreate();
  const [submitError, setSubmitError] = useState(false);

  const handleFlowChange = event => {
    setFlowID(event.target.value);
  };

  const createFlowInstance = () => {
    setSubmitError(false);
    trigger({
      flow_id: flowID,
    })
      .then(() => {
        mutateFlowInstances();
        setDialogOpen(false);
      })
      .catch(e => {
        setSubmitError(true);
        console.error(e);
      });
  };

  return (
    <>
      <ListItemButton dense sx={{ pl: 4 }} onClick={() => setDialogOpen(true)}>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText primary="Flow Instance" />
      </ListItemButton>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="confirm-flow-instance-dialog-title"
        aria-describedby="confirm-flow-instance-dialog-description"
      >
        <DialogTitle id="confirm-flow-instance-dialog">{'Add Flow Instance'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-flow-instance-dialog-description">
            Enable a flow for current organization:
          </DialogContentText>
          {error && (
            <DialogContentText color="error">
              Whoops, something went wrong while loading the flows!
            </DialogContentText>
          )}
          {flows && (
            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel id="flow-label">Flow</InputLabel>
              <Select
                name="flow_id"
                labelId="flow-label"
                id="flow-select"
                label="Flow"
                value={flowID}
                onChange={handleFlowChange}
              >
                {flows.flows.map(flow => (
                  <MenuItem value={flow.id} key={flow.id}>
                    <ListItemText>{flow.name}</ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {submitError && (
            <DialogContentText color="error">Whoops, something went wrong!</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} disabled={inProgress}>
            Cancel
          </Button>
          <Button
            onClick={() => createFlowInstance()}
            autoFocus
            disabled={inProgress || error || flowID === undefined}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddFlowInstanceButton;
