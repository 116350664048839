import { Skeleton, Box, Typography, Card, CardContent } from '@mui/material';

export default function RICardSkeleton() {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography fontSize={14} color="text.secondary" textTransform="uppercase" gutterBottom>
          <Skeleton width="50%" />
        </Typography>
        <Typography variant="h5">
          <Skeleton height="50px" />
        </Typography>
        <Typography mt={2} color="#BA1A1A" variant="h5">
          <Skeleton width="30%" />
        </Typography>
        <Typography display="flex" gap="5px" color="#BA1A1A" variant="body2">
          <Skeleton width="30%" />
        </Typography>
        <Typography variant="body2" mt={1} color="gray">
          <Skeleton height="70px" />
        </Typography>
        <Box width="30%">
          <Skeleton />
        </Box>
      </CardContent>
    </Card>
  );
}
