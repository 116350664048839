import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from '@mui/material';
import useRoleCheck from '../hooks/useRoleCheck';

const ProtectedRoute = ({ children, roles }) => {
  const { isAuthenticated } = useAuth0();
  const hasRequiredRole = useRoleCheck(roles);

  if (!isAuthenticated) {
    // Redirect to login or show a relevant message
    return <Navigate to="/login" />;
  }

  if (!hasRequiredRole) {
    // Show an alert or redirect if the user doesn't have the required role
    return <Alert severity="error">You are not authorized to view this content.</Alert>;
  }

  // Render the children if the user has the required role
  return children;
};

export default ProtectedRoute;
