import { Modal, Box, TextField, Typography, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';

import useUpdateInsight from '../../../hooks/useUpdateInsight';
import { ErrorNotif, SuccessNotif, InfoNotif } from './Notifications';
import useClearCache from '../../../hooks/useClearCache';

const ModalWrapper = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '820px',
  width: '85%',
  boxShadow: 'black 0px 0px 20px',
  padding: '40px',
  backgroundColor: '#fafafa',
  '@media (max-width: 900px)': {
    width: '100%',
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    transform: 'none',
    padding: '40px',
    overflowY: 'auto',
    maxHeight: '100vh',
    minWidth: 'unset',
  },
});

const DisplayWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  maxHeight: '80vh',
});

const TextAreaWrapper = styled(Box)({
  width: '100%',
});

const ButtonsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  marginTop: '20px',
});

const inputStyle = {
  '& .MuiTextField-root': {
    height: '100%',
  },
  height: '90%',
  overflow: 'auto',
};

function EditInsightModal({ insight, isOpen, closeModal, mutateInsight }) {
  const [json, setJson] = useState('');
  const [error, setError] = useState('');

  const clearCache = useClearCache();

  useEffect(() => {
    setJson(JSON.stringify(insight, null, 4));
    setError('');
  }, [insight]);

  const handleChange = event => {
    const newText = event.target.value;
    setJson(newText);
    try {
      const _newJson = JSON.parse(newText);
      setError('');
    } catch (_e) {
      setError('Invalid JSON');
    }
  };

  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const [showErrorNotif, setShowErrorNotif] = useState(false);
  const [showInfoNotif, setShowInfoNotif] = useState(false);

  const { trigger, inProgress, error: _httpError } = useUpdateInsight(insight.displayId);

  const handleSave = () => {
    const dataAtTheTimeOfSave = JSON.parse(json);
    trigger(dataAtTheTimeOfSave)
      .then(() => {
        clearCache();
        mutateInsight(dataAtTheTimeOfSave, { revalidate: true });
        setShowSuccessNotif(true);
      })
      .catch(e => {
        if (e.cause?.status === 304) {
          return setShowInfoNotif(true);
        }
        console.error(e);
        setShowErrorNotif(true);
      });
  };

  const onClose = () => {
    if (inProgress) return;
    closeModal();
    setShowSuccessNotif(false);
    setShowErrorNotif(false);
  };

  return (
    <>
      <SuccessNotif
        showNotif={showSuccessNotif}
        handleCloseNotif={() => setShowSuccessNotif(false)}
        message="Insight updated successfully"
      />
      <ErrorNotif
        showNotif={showErrorNotif}
        handleCloseNotif={() => setShowErrorNotif(false)}
        message="Failed to update Insight"
      />
      <InfoNotif
        showNotif={showInfoNotif}
        handleCloseNotif={() => setShowInfoNotif(false)}
        message="No changes detected"
      />
      <Modal data-testid="edit-incident-modal" open={isOpen} onClose={onClose}>
        <ModalWrapper>
          <DisplayWrapper>
            <TextAreaWrapper>
              <Typography
                variant="h5"
                component="h2"
                id="modal-modal-title"
                sx={{ marginBottom: '20px' }}
              >
                Edit Insight
              </Typography>
              <TextField
                multiline
                fullWidth
                variant="outlined"
                error={!!error}
                value={json}
                onChange={handleChange}
                sx={inputStyle}
                helperText={error}
                disabled={inProgress}
              />
            </TextAreaWrapper>
          </DisplayWrapper>
          <ButtonsWrapper>
            <Button variant="text" size="large" color="secondary" onClick={onClose}>
              CLOSE
            </Button>
            <Button
              variant="text"
              size="large"
              disabled={!!error || inProgress}
              onClick={handleSave}
            >
              SAVE
            </Button>
          </ButtonsWrapper>
        </ModalWrapper>
      </Modal>
    </>
  );
}

export default EditInsightModal;
