import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import theme from './theme';
import Auth0ProviderWithHistory from './components/Auth0ProviderWithHistory';
import Home from './layout/Home';
import StateProvider from './State';
import SWRProvider from './SWRProvider';

const { REACT_APP_CUI_GRAPE_API_AUDIENCE, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID } =
  process.env;

function App() {
  return (
    <StateProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Auth0ProviderWithHistory
            audience={REACT_APP_CUI_GRAPE_API_AUDIENCE}
            domain={REACT_APP_AUTH0_DOMAIN}
            clientId={REACT_APP_AUTH0_CLIENT_ID}
          >
            <SWRProvider>
              <div className="App">
                <Home />
              </div>
            </SWRProvider>
          </Auth0ProviderWithHistory>
        </Router>
      </ThemeProvider>
    </StateProvider>
  );
}

export default App;
