import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Popover,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';

const UserProfile = () => {
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAvatarClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutFn = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;

  // Function to trim the email and keep the domain part visible
  const trimmedEmail = email => {
    const [localPart, domainPart] = email.split('@');
    const trimmedLocalPart = localPart.length > 6 ? localPart.substring(0, 6) + '...' : localPart;
    return `${trimmedLocalPart}@${domainPart}`;
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title="Profile">
        <IconButton onClick={handleAvatarClick} sx={{ marginLeft: 1 }}>
          <Avatar sx={{ width: 40, height: 40 }} src={user.picture} alt={user.name} />
        </IconButton>
      </Tooltip>
      <Box
        sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', marginLeft: 1 }}
        onClick={handleAvatarClick}
      >
        <Tooltip title={user.name}>
          <Typography variant="subtitle1" sx={{ cursor: 'pointer' }}>
            {user.name.length > 21 ? `${user.name.substring(0, 18)}...` : user.name}
          </Typography>
        </Tooltip>
        <Tooltip title={user.email}>
          <Typography variant="body2" sx={{ color: 'text.secondary', cursor: 'pointer' }}>
            {trimmedEmail(user.email)}
          </Typography>
        </Tooltip>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              User Profile
            </Typography>
            <Typography variant="body2">Name: {user.name}</Typography>
            <Typography variant="body2">Email: {user.email}</Typography>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={logoutFn}>
              Logout
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </Box>
  );
};

export default UserProfile;
