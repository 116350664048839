import { useState, useEffect } from 'react';
import { SWRConfig } from 'swr';
import { useAuth0 } from '@auth0/auth0-react';

import Fetcher from './utils/fetcher';

function SWRProvider({ children }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [fetcher, setFetcher] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(Fetcher)
        .then(f => setFetcher(() => f));
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const options = {
    fetcher,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  };

  return <SWRConfig value={options}>{children}</SWRConfig>;
}

export default SWRProvider;
