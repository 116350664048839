import { Box, Button, Grid, Modal, IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';

import WaterfallChart from './WaterfallChart';
import CardTitle from './riCard/RICardTitle';
import RICardTrend from './riCard/RICardTrend';
import RIModalSection from './riModal/RIModalSection';
import { Close } from '@mui/icons-material';

const riModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '820px',
  maxWidth: '1024px',
  width: '85%',
  boxShadow: 'black 0px 0px 20px',
  padding: '40px',
  backgroundColor: '#fafafa', // solfa solfa mi do si mimimi
  '@media (max-width: 900px)': {
    width: '100%',
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    transform: 'none',
    padding: '30px',
    overflowY: 'auto',
    maxHeight: '100vh',
    minWidth: 'unset',
  },
};

const leftColumnStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: 'flex-start',
};

const rightColumnStyle = {
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'flex-end',
  flexDirection: 'column',
  gap: '10px',
};

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};

const closeBtnStyle = {
  fontSize: '14px',
  fontWeight: '500',
};

const moreBtnStyle = {
  fontSize: '14px',
  fontWeight: '500',
};

const listStyle = {
  paddingInlineStart: '30px',
  margin: '0',
};

const closeBtnSx = {
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '5px',
  display: { md: 'none' },
};

function RIModal({ incident, isOpen, closeModal }) {
  const {
    revenueDelta,
    incidentTimeStamp,
    category,
    revenueExpectedDeviationPct,
    currency,
    lv2Overview,
    lv2KeyInsights,
    lv2Actions,
    lv2VisualSummary,

    baseline,
    productViewsRevenueImpact,
    cartsRevenueImpact,
    checkoutsRevenueImpact,
    ordersRevenueImpact,
    aovRevenueImpact,
    revenueActual,
    funnelMetrics,
    id,
  } = incident;

  const waterfallChartValues = [
    baseline,
    productViewsRevenueImpact,
    cartsRevenueImpact,
    checkoutsRevenueImpact,
    ordersRevenueImpact,
    aovRevenueImpact,
    revenueActual,
  ];

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={riModalStyle}>
        <IconButton sx={closeBtnSx} onClick={closeModal}>
          <Close />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={rowStyle}>
            <CardTitle date={incidentTimeStamp} title={category} />
            <RICardTrend
              revenueDelta={revenueDelta}
              currency={currency}
              revenueExpectedDeviationPct={revenueExpectedDeviationPct}
            />
          </Grid>

          <Grid item xs={12} sx={{ ...rightColumnStyle, display: { md: 'none' } }}>
            <WaterfallChart
              values={waterfallChartValues}
              title={lv2VisualSummary}
              currency={currency}
              width="100%"
              height="450px"
              funnelMetrics={funnelMetrics}
            />
          </Grid>

          <Grid item xs={12} md={5} sx={leftColumnStyle}>
            <RIModalSection title="Overview">
              {
                <ul style={listStyle}>
                  {lv2Overview.map((el, i) => (
                    <li key={i}>{el}</li>
                  ))}
                </ul>
              }
            </RIModalSection>
            <RIModalSection title="Key Insights">
              {
                <ul style={listStyle}>
                  {lv2KeyInsights.map((el, i) => (
                    <li key={i}>{el}</li>
                  ))}
                </ul>
              }
            </RIModalSection>
            <RIModalSection title="Actions">
              {
                <ul style={listStyle}>
                  {lv2Actions.map((el, i) => (
                    <li key={i}>{el}</li>
                  ))}
                </ul>
              }
            </RIModalSection>
          </Grid>

          <Grid item md={7} sx={{ ...rightColumnStyle, display: { xs: 'none', md: 'block' } }}>
            <WaterfallChart
              values={waterfallChartValues}
              title={lv2VisualSummary}
              currency={currency}
              width="100%"
              height="450px"
              funnelMetrics={funnelMetrics}
            />
          </Grid>

          <Grid item xs={12} sx={{ ...rowStyle, margin: '16px 0 0 0' }}>
            <Button
              variant="text"
              size="small"
              color="secondary"
              onClick={closeModal}
              sx={closeBtnStyle}
            >
              CLOSE
            </Button>
            <Button
              variant="text"
              component={NavLink}
              to={`/revenue-insights/${id}`}
              state={{ incident }}
              size="small"
              sx={moreBtnStyle}
            >
              LEARN MORE
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default RIModal;
