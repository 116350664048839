import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3',
    },
    success: {
      main: '#ecf9f3',
    },
    secondary: {
      main: '#0000008a',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 400,
          textAlign: 'left',
          color: 'rgba(0, 0, 0, 0.80)',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          fontSize: 'small',
          color: 'lightgray',
        },
        markLabelActive: {
          color: 'black',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        labelMedium: {
          fontSize: '14px',
          fontWeight: 400,
          color: 'rgba(0, 0, 0, 0.60)',
          letterSpacing: 0.25,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          color: 'black',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          color: 'black',
          borderRadius: '5px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderWidth: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          color: '#00c853',
        },
      },
    },
  },
});

export default theme;
