import React, { useState } from 'react';
import useSWR from 'swr';
import {
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TablePagination,
  Avatar,
} from '@mui/material';

function UserList() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data, error } = useSWR(
    `${process.env.REACT_APP_INSIGHTS_APP_BFF_API_URL}/users?page=${page}&pageSize=${rowsPerPage}`,
    { shouldRetryOnError: false },
  );

  // Handling sorting and pagination changes
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing the number of rows per page
  };

  // Sort the rows
  const sortedRows = (data?.members || []).sort((a, b) => {
    let compareValue = 0;

    switch (orderBy) {
      case 'name':
        compareValue = a.name.localeCompare(b.name);
        break;
      case 'email':
        compareValue = a.email.localeCompare(b.email);
        break;
      case 'roles': {
        // Assuming roles is an array of objects with a 'name' property
        // Concatenate all role names and compare. Adjust as needed based on your data structure.
        const rolesA = a.roles.map(role => role.name).join(', ');
        const rolesB = b.roles.map(role => role.name).join(', ');
        compareValue = rolesA.localeCompare(rolesB);
        break;
      }
      default:
        break;
    }

    return order === 'asc' ? compareValue : -compareValue;
  });

  if (error) return <div>Error loading users.</div>;
  if (!data)
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={event => handleRequestSort(event, 'name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'email'}
                  direction={orderBy === 'email' ? order : 'asc'}
                  onClick={event => handleRequestSort(event, 'email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'roles'}
                  direction={orderBy === 'roles' ? order : 'asc'}
                  onClick={event => handleRequestSort(event, 'roles')}
                >
                  Roles
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.map(user => (
              <TableRow key={user.user_id}>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Avatar src={user.picture} alt={user.name} sx={{ marginRight: 1 }} />
                    {user.name}
                  </Box>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {user.roles && user.roles.length > 0
                    ? user.roles.map(role => role.name).join(', ')
                    : 'None'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.total} // Total count of items
        page={page} // Current page
        onPageChange={handleChangePage} // Handler for page change
        rowsPerPage={rowsPerPage} // Rows per page
        onRowsPerPageChange={handleChangeRowsPerPage} // Handler for changing rows per page
      />
    </Paper>
  );
}

export default UserList;
