import useSWR from 'swr';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useFlowInstance = flowId => {
  const url = REACT_APP_INSIGHTS_APP_BFF_API_URL + `/flow_instances/${flowId}`;

  const { data, error, isLoading, mutate } = useSWR(url, { shouldRetryOnError: false });

  return {
    flowInstance: data,
    error,
    isLoading,
    mutate,
  };
};

export default useFlowInstance;
