import { Grid } from '@mui/material';
import RICardTitle from '../riCard/RICardTitle';
import RICardTrend from '../riCard/RICardTrend';
import RIModalSection from '../riModal/RIModalSection';
import WaterfallChart from '../WaterfallChart';
import LimitsChart from '../LimitsChart';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

const headerSx = {
  display: 'flex',
  justifyContent: 'space-between',
};

const containerSx = { gap: '20px' };

const RIDetailsMain = ({ insight }) => {
  const {
    incidentTimeStamp,
    title,
    keyMetric,
    revenueDelta,
    revenueExpectedDeviationPct,
    visualSummary,
    baseline,
    anomalyDetectionMode,
    incidentDetails,
    rootCauseAnalysisSummary,
    rootCauseAnalysisDetails,
    funnelMetrics,
    granularity,
    isSummary,
  } = insight;

  // Sanitize and parse the HTML content for the specified elements
  const sanitizedIncidentDetails = parse(DOMPurify.sanitize(incidentDetails));
  const sanitizedRootCauseAnalysisSummary = parse(DOMPurify.sanitize(rootCauseAnalysisSummary));
  const sanitizedRootCauseAnalysisDetails = parse(DOMPurify.sanitize(rootCauseAnalysisDetails));

  const isWeeklySummary = isSummary === true && granularity === 'weekly';

  return (
    <Grid data-testid="ri-details-main" container sx={containerSx}>
      <Grid item xs={12} sx={headerSx}>
        <RICardTitle date={incidentTimeStamp} title={title} />
        <RICardTrend
          currency={keyMetric.symbol}
          revenueDelta={revenueDelta}
          revenueExpectedDeviationPct={revenueExpectedDeviationPct}
        />
      </Grid>

      <Grid item xs={12}>
        <RIModalSection title="Revenue Anomaly">{sanitizedIncidentDetails}</RIModalSection>
      </Grid>

      <Grid item xs={12}>
        <LimitsChart
          baseline={baseline}
          anomalyDetectionMode={anomalyDetectionMode}
          keyMetric={keyMetric}
        />
      </Grid>

      <Grid item xs={12}>
        <RIModalSection title="Root Cause Analysis">
          {sanitizedRootCauseAnalysisSummary}
        </RIModalSection>
      </Grid>

      {isWeeklySummary && (
        <Grid item xs={12}>
          <WaterfallChart
            title={visualSummary}
            currency={keyMetric.symbol}
            width="100%"
            height="500px"
            funnelMetrics={funnelMetrics}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <RIModalSection>{sanitizedRootCauseAnalysisDetails}</RIModalSection>
      </Grid>

      {!isWeeklySummary && (
        <Grid item xs={12}>
          <WaterfallChart
            title={visualSummary}
            currency={keyMetric.symbol}
            width="100%"
            height="500px"
            funnelMetrics={funnelMetrics}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default RIDetailsMain;
