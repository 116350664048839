import { Box, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

import { displayValue, displayPercentage } from '../../../utils/display';

export default function RICardTrend({ currency, revenueDelta, revenueExpectedDeviationPct }) {
  const upTrend = revenueDelta > 0;
  const trendIcon = upTrend ? <TrendingUpIcon /> : <TrendingDownIcon />;
  const trendColor = upTrend ? '#01973F' : '#9B1515';

  const revenueDeltaStyle = {
    color: trendColor,
  };

  const trendStyle = {
    display: 'flex',
    gap: '5px',
    color: trendColor,
  };

  return (
    <Box>
      <Typography sx={revenueDeltaStyle} variant="h5">
        {currency + displayValue(revenueDelta)}
      </Typography>
      <Typography sx={trendStyle} variant="body2">
        {trendIcon} {displayPercentage(revenueExpectedDeviationPct * 100)}% vs. baseline
      </Typography>
    </Box>
  );
}
