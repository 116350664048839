import { createContext, useState } from 'react';

const State = createContext();

export { State };

const StateProvider = ({ children }) => {
  const initialState = {
    httpErrorNotifVisible: false,
  };

  const [state, setState] = useState(initialState);

  function updateState(updates) {
    setState({ ...state, ...updates });
  }

  return <State.Provider value={{ state, updateState }}>{children}</State.Provider>;
};

export default StateProvider;
