import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  Alert,
} from '@mui/material';
import useIntegrationUpdate from '../../hooks/useIntegrationUpdate';
import IntegrationForm from './IntegrationForm';

function UpdateIntegrationDialog({
  integration,
  flowInstanceIntegration,
  isOpen,
  closeDialog,
  mutateFlowInstance,
}) {
  const { connectors } = integration;
  const { flowInstanceId } = useParams();

  const [errorMetadata, setErrorMetadata] = useState([]);
  const [errorResponse, setErrorResponse] = useState(false);
  const [metadata, setMetadata] = useState(flowInstanceIntegration.connector_metadata);
  const [connectorId, setConnectorId] = useState(flowInstanceIntegration.connector?.id);
  const [statusChecked, setStatusChecked] = useState(flowInstanceIntegration.status === 'active');

  useEffect(() => {
    setConnectorId(flowInstanceIntegration.connector?.id);
    setStatusChecked(flowInstanceIntegration.status === 'active');
    setErrorMetadata([]);
  }, [flowInstanceIntegration, isOpen]);

  const {
    trigger,
    inProgress,
    error: _error,
  } = useIntegrationUpdate(flowInstanceId, flowInstanceIntegration.id);

  const innerCloseDialog = () => {
    setErrorResponse(false);
    setErrorMetadata([]);
    closeDialog();
  };

  const handleMetadataChange = metadata => {
    setMetadata(metadata);
  };

  const handleConnectorChange = connector_id => {
    setConnectorId(connector_id);
  };

  const handleStatusChange = event => {
    setStatusChecked(event.target.checked);
  };

  const updateErrors = errors => {
    setErrorMetadata(errors);
  };

  const submitIntegration = () => {
    setErrorResponse(false);
    trigger({
      connector_id: connectorId,
      connector_metadata: metadata,
      status: statusChecked ? 'active' : 'inactive',
    })
      .then(() => {
        mutateFlowInstance();
        innerCloseDialog();
      })
      .catch(e => {
        setErrorResponse(true);
        console.error(e);
      });
  };

  return (
    <Dialog open={isOpen} onClose={innerCloseDialog}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {integration.name}
        <Switch align="right" checked={statusChecked} onChange={handleStatusChange} name="status" />
      </DialogTitle>
      <DialogContent>
        {errorResponse && <Alert severity="error">Whoops! An error occurred.</Alert>}

        <IntegrationForm
          updateConnector={handleConnectorChange}
          updateMetadata={handleMetadataChange}
          updateErrors={updateErrors}
          connectors={connectors}
          flowInstanceIntegration={flowInstanceIntegration}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={innerCloseDialog}>Cancel</Button>
        <Button onClick={submitIntegration} disabled={inProgress || errorMetadata.length > 0}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateIntegrationDialog;
