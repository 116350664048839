import {
  Divider,
  Box,
  List,
  ListItemButton as MuiListItemButton,
  ListItemText,
  ListSubheader,
  ListItemIcon,
  Collapse,
} from '@mui/material';
import {
  CreditCard,
  Logout,
  Work,
  Group,
  Help,
  AddBox,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import useFlowInstances from '../hooks/useFlowInstances';
import useRoleCheck from '../hooks/useRoleCheck';
import UserProfile from '../components/UserProfile';
import UserOrganizations from '../components/UserOrganizations';
import AddFlowInstanceButton from './sidebar/AddFlowInstanceButton';

const activeMenuItemSx = theme => ({
  color: theme.palette.primary.main,
  '& .MuiListItemText-primary': { color: theme.palette.primary.main },
  '& .MuiListItemIcon-root': { color: theme.palette.primary.main },
});

const sidebarSx = theme => ({
  '& .MuiListItemButton-root': { borderRadius: '4px' },
  '& .MuiListItemText-primary': {
    fontWeight: '500',
    color: 'rgba(0, 0, 0, 0.87)',
    letterSpacing: '0.1px',
    font: '500 14px/21px Roboto, -apple-system, Roboto, Helvetica, sans-serif',
  },
  '& .MuiListItemButton-root:hover': {
    ...activeMenuItemSx(theme),
    backgroundColor: 'unset',
  },
  '& a': { textDecoration: 'none', color: 'unset' },
  '& .MuiList-padding': { p: '10px' },
  '& .MuiList-root>a.active>.MuiListItemButton-root': {
    ...activeMenuItemSx(theme),
    backgroundColor: '#E3F2FD',
  },
});

const subheaderSx = {
  color: '#9E9E9E',
  fontWeight: '400',
  mt: '20px',
  letterSpacing: 0.25,
};

export default function Sidebar({ setMobileOpen }) {
  console.log('env', process.env.NODE_ENV);
  const logoutFn = () => logout({ logoutParams: { returnTo: window.location.origin } });
  const { logout, isAuthenticated } = useAuth0();
  const isAdmin = useRoleCheck('ADMIN');

  const { flowInstances, mutate } = useFlowInstances();

  const [integrationMenuOpen, setIntegrationMenuOpen] = useState(false);
  const integrationsClick = () => {
    setIntegrationMenuOpen(!integrationMenuOpen);
  };

  const handleClick = () => {
    if (setMobileOpen) setMobileOpen(false);
  };

  const ListItemButton = ({ children, ...props }) => (
    <MuiListItemButton onClick={handleClick} {...props}>
      {children}
    </MuiListItemButton>
  );

  return (
    <Box sx={sidebarSx}>
      <aside>
        <Box sx={{ display: { xs: 'none', md: 'block' }, mb: '15px', mt: '15px' }}>
          <UserProfile />
        </Box>
        <UserOrganizations />
        <Divider variant="fullwidth" />

        <List>
          <NavLink to="/revenue-insights">
            <ListItemButton>
              <ListItemIcon>
                <Work />
              </ListItemIcon>
              <ListItemText primary="Revenue Insights" />
            </ListItemButton>
          </NavLink>
          <ListSubheader sx={subheaderSx}>Settings</ListSubheader>
          <ListItemButton onClick={integrationsClick}>
            <ListItemIcon>
              <AddBox />
            </ListItemIcon>
            <ListItemText primary="Integrations" />
            {integrationMenuOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={integrationMenuOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {flowInstances &&
                flowInstances.flowInstances.map(flowInstance => (
                  <NavLink key={flowInstance.id} to={`/integrations/${flowInstance.id}`}>
                    <ListItemButton dense sx={{ pl: 4 }}>
                      <ListItemText primary={flowInstance.flow.name} />
                    </ListItemButton>
                  </NavLink>
                ))}
              {isAdmin && <AddFlowInstanceButton mutateFlowInstances={mutate} />}
            </List>
          </Collapse>
          <ListItemButton>
            <ListItemIcon>
              <CreditCard />
            </ListItemIcon>
            <ListItemText primary="Payment" />
          </ListItemButton>
          {isAuthenticated && isAdmin && (
            <NavLink to="/users">
              <ListItemButton>
                <ListItemIcon>
                  <Group />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
            </NavLink>
          )}
          <ListItemButton
            target="blank"
            href="https://chatgpt.com/g/g-BOYMkCHhH-revenue-accelerator-support"
          >
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItemButton>
          <ListItemButton data-test-id="logout" onClick={logoutFn}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </aside>
    </Box>
  );
}
