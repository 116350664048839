import useMutate from './useMutate';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useUserInteractionCreate = (insightId, type) => {
  const url = `${REACT_APP_INSIGHTS_APP_BFF_API_URL}/insights/${insightId}/user_interactions/${type}`;

  const { trigger, inProgress, error } = useMutate(url, 'POST');

  return { trigger, inProgress, error };
};

export default useUserInteractionCreate;
