const displayValue = n => Math.round(Math.abs(n)).toLocaleString('en-US');

function displayPercentage(n) {
  if (n === null) return NaN;

  const an = Math.abs(n);

  if (an < 1)
    return (Math.round(n * 10) / 10).toLocaleString('en-US', {
      minimumFractionDigits: 1,
    });

  return Math.round(n).toLocaleString('en-US', { minimumFractionDigits: 0 });
}

export { displayValue, displayPercentage };
