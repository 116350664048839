import GlobalNotification from '../../../layout/GlobalNotification';

function SuccessNotif({ showNotif, handleCloseNotif, message = 'Feedback submitted' }) {
  return (
    <GlobalNotification
      showNotif={showNotif}
      handleCloseNotif={handleCloseNotif}
      severity="success"
      message={message}
    />
  );
}

function ErrorNotif({
  showNotif,
  handleCloseNotif,
  message = 'There was an error submitting feedback',
}) {
  return (
    <GlobalNotification
      showNotif={showNotif}
      handleCloseNotif={handleCloseNotif}
      severity="error"
      message={message}
    />
  );
}

function InfoNotif({ showNotif, handleCloseNotif, message }) {
  return (
    <GlobalNotification
      showNotif={showNotif}
      handleCloseNotif={handleCloseNotif}
      severity="info"
      message={message}
    />
  );
}

export { SuccessNotif, ErrorNotif, InfoNotif };
