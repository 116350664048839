import { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import useMutate from './useMutate';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useVideoPlayer = (isOpen, onClose, videoSrc) => {
  const playerRef = useRef(null);
  const videoElementRef = useRef(null);
  const [videoReady, setVideoReady] = useState(false);
  const recordingName = videoSrc
    .split('/')
    .filter(x => x)
    .pop()
    .split('.')[0];

  const { trigger, error } = useMutate(
    `${REACT_APP_INSIGHTS_APP_BFF_API_URL}/signedCookies/${recordingName}`,
    'GET',
  );

  useEffect(() => {
    const loadVideo = async () => {
      await trigger({}, { credentials: 'include' });

      playerRef.current = videojs(
        videoElementRef.current,
        {
          controls: true,
          autoplay: true,
          preload: 'auto',
          fluid: true,
          responsive: true,
          sources: [{ src: `${videoSrc}/${recordingName}.m3u8`, type: 'application/x-mpegURL' }],
          html5: {
            vhs: {
              withCredentials: true,
            },
          },
        },
        () => {
          console.log('Player is ready.');
        },
      );
    };

    if (isOpen && !playerRef.current) {
      loadVideo().catch(error => console.error(error));
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [isOpen, videoSrc, videoReady]);

  return {
    videoElementRef,
    handleClose: () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      setVideoReady(false);
      onClose();
    },
    error,
  };
};

export default useVideoPlayer;
