import { useState, useEffect } from 'react';
import useSWRMutation from 'swr/mutation';
import { useAuth0 } from '@auth0/auth0-react';

import Fetcher from '../utils/fetcher';

const useMutate = (url, method) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [fetcher, setFetcher] = useState();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then(Fetcher)
        .then(f => setFetcher(() => f));
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const { trigger, isMutating, error } = useSWRMutation([url], fetcher, {
    shouldRetryOnError: false,
  });

  const customTrigger = async (data, httpOverrides = {}, options = {}) => {
    await trigger(
      {
        method,
        ...(method?.toLowerCase() === 'get' || method?.toLowerCase() === 'delete'
          ? {}
          : { body: JSON.stringify(data) }),
        ...httpOverrides,
        headers: { ...httpOverrides?.headers, 'Content-Type': 'application/json' },
      },
      options,
    );
  };

  return { trigger: customTrigger, inProgress: isMutating, error };
};

export default useMutate;
