import { useEffect, useState } from 'react';
import { Box, Paper, Grid, Snackbar, Alert, IconButton } from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ModeEdit } from '@mui/icons-material';
import diff from 'microdiff';

import useRoleCheck from '../../hooks/useRoleCheck';
import useInsight from '../../hooks/useInsight';

import RIDetailsMain from './riDetails/RIDetailsMain';
import AISuggestionWidget from './riDetails/AISuggestionsWidget';
import HypothesesTestedWidget from './riDetails/HypothesesTestedWidget';
import ChatWithYourDataWidget from './riDetails/ChatWithYourDataWidget';
import EditInsightModal from './riDetails/EditInsightModal';
import RIDetailsSkeleton from './riDetails/RIDetailsSkeleton';
import GlobalNotification from '../../layout/GlobalNotification';

const leftSx = {
  padding: { xs: '25px', sm: '35px', md: '40px' },
  position: 'relative',
  borderWidth: {
    xs: 0,
    md: '1px',
  },
  borderRadius: {
    xs: 0,
    md: '4px',
  },
};

const editBtnSx = {
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '5px',
};

function ErrorNotif({ showNotif, handleCloseNotif, message }) {
  return (
    <GlobalNotification
      showNotif={showNotif}
      handleCloseNotif={handleCloseNotif}
      severity="error"
      message={message}
    />
  );
}

const LeftColumn = ({ insight, handleOpenModal }) => {
  const isAdmin = useRoleCheck('ADMIN');

  return (
    <Paper elevation={0} variant="outlined" sx={leftSx}>
      {isAdmin && (
        <IconButton sx={editBtnSx} onClick={handleOpenModal}>
          <ModeEdit data-testid="riDetails-edit" />
        </IconButton>
      )}
      <RIDetailsMain insight={insight} />
    </Paper>
  );
};

const rightSx = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: '20px',
};

const RightColumn = ({ insight }) => {
  return (
    <Box sx={rightSx}>
      <AISuggestionWidget
        insightId={insight.displayId}
        aiSuggestions={insight.aISuggestions}
        actions={insight.aiActions}
      />
      <HypothesesTestedWidget insightId={insight.displayId} hypotheses={insight.hypotheses} />
      <ChatWithYourDataWidget insightId={insight.displayId} />
    </Box>
  );
};

const InfoSnackbar = ({ showInfo, setShowInfo }) => {
  const handleClose = () => setShowInfo(false);

  return (
    <Snackbar
      open={showInfo}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
        Failed to fetch data from the server, displaying a cached version of the Insight.
      </Alert>
    </Snackbar>
  );
};

const WrongOrgAlert = ({ isOpen, insightAuth0DisplayName }) => {
  const [showAlert, setShowAlert] = useState(isOpen);
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();

  const switchOrg = () => {
    loginWithRedirect({ appState: { returnTo: location.pathname } });
  };

  return (
    <Snackbar
      data-testid="wrong-org-alert"
      open={showAlert}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={() => setShowAlert(false)} severity="warning">
        The insight you are trying to view belongs to organization '{insightAuth0DisplayName}
        '.&nbsp;
        <a onClick={switchOrg} style={{ cursor: 'pointer' }}>
          Switch to view
        </a>
        .
      </Alert>
    </Snackbar>
  );
};

export default function RIDetails() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  let { insightId } = useParams();

  const stateInsight = state?.insight;
  const { insight: fetchedInsight, error, mutate } = useInsight(insightId);
  const insight = fetchedInsight || stateInsight;
  const [showInfo, setShowInfo] = useState(false);

  // scroll to top on page load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // always revalidate history state with fetched insight
  useEffect(() => {
    if (stateInsight && fetchedInsight && diff(stateInsight, fetchedInsight).length > 0) {
      // only update if there's a difference, otherwise infinite loop
      navigate(pathname, { state: { insight: fetchedInsight }, replace: true }); // hacky way to update history state
    }
  }, [stateInsight, fetchedInsight]);

  const [showErrorNotif, setShowErrorNotif] = useState(false);
  const handleCloseErrorNotif = () => setShowErrorNotif(false);

  useEffect(() => {
    if (error) {
      console.error(error);
      if (insight) setShowInfo(true);
      else setShowErrorNotif(true);
      //else updateState({ httpErrorNotifVisible: true });
    }
  }, [error]);

  const [showEditModal, setShowEditModal] = useState(false);
  const handleOpenModal = () => setShowEditModal(true);
  const handleCloseModal = () => setShowEditModal(false);

  const isWrongOrg = error?.status === 409;
  const notFound = error?.status === 404;

  if (!insight) {
    return (
      <>
        <RIDetailsSkeleton />
        {isWrongOrg ? (
          <WrongOrgAlert
            isOpen={true}
            insightAuth0DisplayName={error.cause.correctOrg.display_name}
          />
        ) : (
          <ErrorNotif
            showNotif={showErrorNotif}
            handleCloseNotif={handleCloseErrorNotif}
            message={notFound ? 'Insight not found' : 'Failed to fetch Insight'}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} lg={8}>
          <LeftColumn insight={insight} handleOpenModal={handleOpenModal} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RightColumn insight={insight} />
        </Grid>
      </Grid>
      <InfoSnackbar showInfo={showInfo} setShowInfo={setShowInfo} />
      {insight && (
        <EditInsightModal
          insight={insight}
          isOpen={showEditModal}
          closeModal={handleCloseModal}
          mutateInsight={mutate}
        />
      )}
    </>
  );
}
