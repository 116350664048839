import React, { useContext } from 'react';
import { IconButton, Typography, Tooltip, Card, CardContent, CardActions } from '@mui/material';
import { State } from '../State';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useNavigate } from 'react-router-dom';

const orgDisplayNameSx = {
  fontWeight: '500',
  fontSize: '20px',
  letterSpacing: '0.15px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

const UserOrganizations = () => {
  const navigate = useNavigate();
  const { state } = useContext(State);

  const handleLoginNavigation = () => {
    navigate('/login');
  };

  // Function to trim the displayName
  const trimmedDisplayName = displayName => {
    // Ensure displayName is defined, fallback to an empty string if it's not
    const safeDisplayName = displayName || '';
    return safeDisplayName.length > 15 ? `${safeDisplayName.substring(0, 15)}...` : safeDisplayName;
  };
  return (
    <Card sx={orgDisplayNameSx}>
      <CardContent>
        <Tooltip title={state.displayName}>
          <Typography variant="h6">{trimmedDisplayName(state.displayName)}</Typography>
        </Tooltip>
      </CardContent>
      <CardActions>
        <Tooltip title="Switch Organization">
          <IconButton onClick={handleLoginNavigation}>
            <SwapHorizIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default UserOrganizations;
