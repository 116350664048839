import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

export default function HypothesesModal({
  hypotheses,
  clickedHypothesisIndex,
  isOpen,
  handleClose,
}) {
  const [hypothesis, setHypothesis] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const hypothesesLength = hypotheses.length;

  useEffect(() => {
    setHypothesis(hypotheses[clickedHypothesisIndex]);
    setSelectedIndex(clickedHypothesisIndex);
  }, [clickedHypothesisIndex, isOpen]);

  const nextHypothesis = () => {
    const newIndex = selectedIndex === hypothesesLength - 1 ? 0 : selectedIndex + 1;
    setHypothesis(hypotheses[newIndex]);
    setSelectedIndex(newIndex);
  };

  const prevHypothesis = () => {
    const newIndex = selectedIndex === 0 ? hypothesesLength - 1 : selectedIndex - 1;
    setHypothesis(hypotheses[newIndex]);
    setSelectedIndex(newIndex);
  };

  return (
    <Dialog
      data-testid="hypotheses-modal"
      onClose={handleClose}
      scroll="paper"
      open={isOpen}
      sx={{ '.MuiDialog-paper': { height: '500px' } }}
    >
      <DialogTitle>{hypothesis?.statement}</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Analysis
        </Typography>
        <Typography variant="body1" gutterBottom>
          {hypothesis?.analysis}
        </Typography>
        <br />

        <Typography variant="h6" gutterBottom>
          Results
        </Typography>
        <Typography variant="body1" gutterBottom>
          {parse(DOMPurify.sanitize(hypothesis?.result))}
        </Typography>
        <br />

        <Typography variant="h6" gutterBottom>
          Verdict
        </Typography>
        <Typography variant="body1" gutterBottom>
          {hypothesis?.verdict}
        </Typography>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button startIcon={<ArrowBack />} onClick={prevHypothesis}>
          Previous
        </Button>
        <Button endIcon={<ArrowForward />} onClick={nextHypothesis}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
}
