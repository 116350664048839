import { Skeleton, Grid, Paper, Box, Card } from '@mui/material';

const leftSx = {
  padding: '40px',
  position: 'relative',
};

const rightSx = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: '20px',
};

const headerSx = {
  display: 'flex',
  justifyContent: 'space-between',
};

const containerSx = { gap: '20px' };

const cardStyles = {
  p: 2,
};

export default function RIDetailsSkeleton() {
  return (
    <Grid data-testid="ri-details-skeleton" container direction="row" spacing={2}>
      <Grid item xs={12} lg={8}>
        <Paper elevation={0} variant="outlined" sx={leftSx}>
          <Grid container sx={containerSx}>
            <Grid item xs={12} sx={headerSx}>
              <Skeleton variant="text" sx={{ fontSize: '4rem', width: '30%' }} />
            </Grid>

            <Grid item xs={12}>
              <Skeleton variant="text" sx={{ fontSize: '2rem', width: '40%' }} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            </Grid>

            <Grid item xs={12}>
              <Skeleton variant="rounded" width="80%" height="400px" />
            </Grid>

            <Grid item xs={12}>
              <Skeleton variant="text" sx={{ fontSize: '2rem', width: '40%' }} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            </Grid>

            <Grid item xs={12}>
              <Skeleton variant="rounded" width="80%" height="400px" />
            </Grid>

            <Grid item xs={12}>
              <Skeleton variant="text" sx={{ fontSize: '2rem', width: '40%' }} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={rightSx}>
          <Card sx={cardStyles}>
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
          </Card>
          <Card sx={cardStyles}>
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
          </Card>
          <Card sx={cardStyles}>
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
          </Card>
          <Card sx={cardStyles}>
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
            <Skeleton variant="text" sx={{ fontSize: '3rem', width: '60%' }} />
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
