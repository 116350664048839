import { useAuth0 } from '@auth0/auth0-react';

const ROLES = { ADMIN: 'Insights App Admin', USER: 'Insights App User' };

const useRoleCheck = requiredRole => {
  const { user } = useAuth0();
  const userRoles = user[process.env.REACT_APP_ROLE_NAMESPACE + '/roles'] || [];
  const hasRequiredRole = userRoles.includes(ROLES[requiredRole]);
  return hasRequiredRole;
};

export default useRoleCheck;
