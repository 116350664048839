import React, { useContext } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { State } from '../State';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function HttpErrorNotif() {
  const { state, updateState } = useContext(State);

  const handleClose = () => {
    updateState({ httpErrorNotifVisible: false });
  };
  return (
    <Snackbar
      open={state.httpErrorNotifVisible}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        Failed to fetch data, please refresh the page to try again!
      </Alert>
    </Snackbar>
  );
}

export default HttpErrorNotif;
