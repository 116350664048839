import useMutate from './useMutate';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useUpdateInsight = insightId => {
  const url = REACT_APP_INSIGHTS_APP_BFF_API_URL + `/api/v1/insights/${insightId}`;

  const { trigger, inProgress, error } = useMutate(url, 'PUT');

  return { trigger, inProgress, error };
};

export default useUpdateInsight;
