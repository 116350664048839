import useSWR from 'swr';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useFlowInstances = () => {
  const url = `${REACT_APP_INSIGHTS_APP_BFF_API_URL}/flow_instances`;
  const { data, error, isLoading, mutate } = useSWR(url, {
    shouldRetryOnError: true,
  });

  return {
    flowInstances: data,
    error,
    isLoading,
    mutate,
  };
};

export default useFlowInstances;
