import { Box, styled } from '@mui/material';

const TitleBar = styled(Box)({
  marginBottom: '24px',
  width: '100%',
  gap: '20px',
  borderBottom: '1px solid lightgray',
  display: 'flex',
  height: 'fit-content',
});

export default TitleBar;
