import useMutate from './useMutate';

const { REACT_APP_INSIGHTS_APP_BFF_API_URL } = process.env;

const useIntegrationCreate = flowInstanceId => {
  const url = `${REACT_APP_INSIGHTS_APP_BFF_API_URL}/flow_instances/${flowInstanceId}/integrations`;

  const { trigger, inProgress, error } = useMutate(url, 'POST');

  return { trigger, inProgress, error };
};

export default useIntegrationCreate;
