import { useAuth0 } from '@auth0/auth0-react';
import { ReportGmailerrorred } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

export default function Error({ errorDescription }) {
  const { logout } = useAuth0();

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        padding: '20px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ReportGmailerrorred sx={{ width: '150px', height: '150px', color: 'tomato' }} />
        <Box component="h1">Error</Box>
        <Box sx={{ mb: '20px', maxWidth: '400px', textAlign: 'center' }}>{errorDescription}</Box>
        <Box>
          <Button
            variant="text"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          >
            Back to login page
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
