import { useState } from 'react';
import {
  Box,
  IconButton,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Create, SmsOutlined, Send } from '@mui/icons-material';
import { SuccessNotif, ErrorNotif } from './Notifications';
import Widget from './Widget';
import { ComingSoonChip, ComingSoonOverlay } from '../common/ComingSoon';

const titleSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const iconButtonSx = {
  position: 'relative',
  top: 0,
  right: 0,
  color: 'text.secondary',
};

const cardHeaderStyles = {
  pb: 0,
  '& .MuiCardHeader-title': {
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: '700',
    textAlign: 'center',
  },
  paddingBottom: 0,
};

const cardContentSx = {
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
};

const Title = () => (
  <Box sx={titleSx}>
    <IconButton aria-label="info" size="small" sx={iconButtonSx}>
      <SmsOutlined />
    </IconButton>
    <Box>CHAT WITH YOUR DATA</Box>
  </Box>
);

export default function ChatWithYourDataWidget() {
  const [showSuccessNotif, setShowSuccessNotif] = useState(false);
  const [showErrorNotif, setShowErrorNotif] = useState(false);

  const handleCloseNotif = () => setShowSuccessNotif(false);
  const [question, setQuestion] = useState('');

  const handleQuestionChange = event => {
    setQuestion(event.target.value);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Widget data-testid="chat-with-your-data-widget">
        <SuccessNotif
          showNotif={showSuccessNotif}
          handleCloseNotif={handleCloseNotif}
          message="Your input has been submitted - this feature will be available soon!"
        />
        <ErrorNotif showNotif={showErrorNotif} handleCloseNotif={() => setShowErrorNotif(false)} />
        <CardHeader action={<ComingSoonChip />} sx={{ paddingBottom: 0 }} />
        <CardHeader title={<Title />} sx={cardHeaderStyles} />
        <CardContent sx={cardContentSx}>
          <Typography variant="body2" color="text.secondary">
            Make a follow-up question. Let us retrieve and analyze the data and return the answer
          </Typography>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            size="small"
            id="question"
            placeholder="Type your question here..."
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Create />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small">
                    <Send />
                  </IconButton>
                </InputAdornment>
              ),
              style: { overflow: 'auto' },
            }}
            value={question}
            onChange={handleQuestionChange}
            inputProps={{ maxLength: 1000, style: { overflow: 'auto' } }}
          />
        </CardContent>
        <ComingSoonOverlay cursor={false} />
      </Widget>
    </div>
  );
}
