import { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Avatar,
  Button,
  Typography,
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import useRoleCheck from '../../hooks/useRoleCheck';
import UpdateIntegrationDialog from './UpdateIntegrationDialog';
import { t, renderTextWithLinks } from '../../utils/textUtils';

const FlowInstanceIntegrationCard = ({
  integration,
  flowInstanceIntegration,
  mutateFlowInstance,
}) => {
  const isAdmin = useRoleCheck('ADMIN');
  const enabledActions = isAdmin;
  const [integrationDialogOpen, setIntegrationDialogOpen] = useState(false);

  return (
    <>
      <Card variant="outlined" sx={{ width: '100%', borderWidth: 1 }}>
        {flowInstanceIntegration.connector && (
          <CardHeader
            avatar={
              <Avatar
                variant="square"
                src={flowInstanceIntegration.connector?.icon}
                alt={flowInstanceIntegration.connector?.name}
                sx={{ '.MuiAvatar-img': { objectFit: 'contain' } }}
              ></Avatar>
            }
            title={flowInstanceIntegration.connector?.name}
            action={
              isAdmin && (
                <Tooltip arrow title={flowInstanceIntegration.status}>
                  <TaskAltIcon
                    color={flowInstanceIntegration.status == 'active' ? 'primary' : 'disabled'}
                  />
                </Tooltip>
              )
            }
            sx={{ '.MuiCardHeader-title': { fontSize: '1rem' } }}
          ></CardHeader>
        )}
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {integration.description}
          </Typography>
          <List>
            {integration &&
              flowInstanceIntegration.connector_metadata &&
              Object.entries(flowInstanceIntegration.connector_metadata).map(([key, value]) => (
                <ListItem key={key} sx={{ wordWrap: 'anywhere' }}>
                  {typeof value === 'string' ? (
                    <ListItemText primary={t(key)} secondary={renderTextWithLinks(value)} />
                  ) : (
                    typeof value === 'object' &&
                    'label' in value && (
                      <ListItemText
                        primary={value?.label}
                        secondary={renderTextWithLinks(value?.value)}
                      />
                    )
                  )}
                </ListItem>
              ))}
          </List>
        </CardContent>

        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Tooltip
            arrow
            title={
              enabledActions
                ? 'Update the integartion'
                : 'You do not have the permissions to edit this integration. Contact us in case of any questions.'
            }
          >
            {/* Tooltip won't work for a disabled button that's why we wrap it in a span element */}
            <span>
              <Button
                size="small"
                disabled={!enabledActions}
                onClick={() => setIntegrationDialogOpen(true)}
              >
                Edit
              </Button>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
      <UpdateIntegrationDialog
        integration={integration}
        flowInstanceIntegration={flowInstanceIntegration}
        isOpen={integrationDialogOpen}
        closeDialog={() => setIntegrationDialogOpen(false)}
        mutateFlowInstance={mutateFlowInstance}
      />
    </>
  );
};

export default FlowInstanceIntegrationCard;
