import React, { useState } from 'react';
import { Drawer, AppBar, Toolbar, Box, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Sidebar from './Sidebar';
import UserProfile from '../components/UserProfile';

const drawerSx = {
  '& .MuiDrawer-paper': { width: 256 },
  display: { xs: 'block' },
  width: 256,
};

const Menubar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Drawer variant="temporary" sx={drawerSx} open={mobileOpen} onClose={handleDrawerToggle}>
        <Sidebar setMobileOpen={setMobileOpen} />
      </Drawer>
      <AppBar sx={{ display: { md: 'none' } }}>
        <Toolbar sx={{ paddingRight: '10px' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ marginLeft: 'auto' }}>
            <UserProfile />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Menubar;
