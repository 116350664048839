import { Backdrop, Chip } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

const overlayStyle = cursor => {
  return {
    backgroundColor: 'rgba(245, 245, 245, 0.50)',
    cursor: cursor ? 'auto' : 'not-allowed',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
};

const chipStyle = {
  '.MuiChip-iconSmall': { fontSize: '14px', marginLeft: '4px' },
  fontWeight: '700',
  color: 'rgb(102, 102, 102)',
  borderColor: 'rgb(102, 102, 102)',
  zIndex: 1,
  position: 'relative',
};

const ComingSoonChip = () => {
  return (
    <Chip
      icon={<HourglassBottomIcon />}
      label="Available soon"
      variant="outlined"
      size="small"
      sx={chipStyle}
    />
  );
};

const ComingSoonOverlay = ({ cursor }) => {
  return <Backdrop open={true} sx={overlayStyle(cursor)}></Backdrop>;
};

export { ComingSoonChip, ComingSoonOverlay };
