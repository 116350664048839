function Fetcher(accessToken) {
  const authHeader = { Authorization: `Bearer ${accessToken}` };

  const fetcher = async (url, { arg: options = {} } = { arg: {} }) => {
    const response = await fetch(url, {
      ...options,
      headers: { ...options.headers, ...authHeader },
    });

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.status = response.status;
      error.cause = await response.json();
      throw error;
    }
    return response.json();
  };

  return fetcher;
}

export default Fetcher;
