function escapeChars(str) {
  return (
    str
      // html specific
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
      // google sheets specific
      .replace(/=/g, '&#61;')
      .replace(/\+/g, '&#43;')
      .replace(/-/g, '&#45;')
      .replace(/@/g, '&#64;')
      .replace(/\|/g, '&#124;')
  );
}

export { escapeChars };
