import { TablePagination } from '@mui/material';

function PaginationControl({ count, page, handleChangePage, disabled, itemsPerPage }) {
  const displayTextFn = ({ from, to }) =>
    `${from} – ${count ? to : '?'} of ` + (!count ? '?' : count !== -1 ? count : `more than ${to}`);

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={itemsPerPage}
      rowsPerPageOptions={[itemsPerPage]}
      onRowsPerPageChange={() => {}}
      disabled={disabled}
      data-testid="pagination-control"
      labelDisplayedRows={displayTextFn}
    />
  );
}

export default PaginationControl;
