import { Box, Typography } from '@mui/material';

const subtitleStyle = {
  color: 'rgba(0, 0, 0, 0.60)',
  fontSize: '16px',
  fontWeight: '700',
  letterSpacing: '0.50px',
  wordWrap: 'break-word',
};

const contentStyle = {
  color: 'rgba(0,0,0,0.60)',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.50px',
  wordWrap: 'break-word',
  margin: '2px 0 0 0',
};

const RIModalSection = ({ title, children }) => (
  <Box>
    {title && <Typography sx={subtitleStyle}>{title}</Typography>}
    <Box sx={contentStyle}>{children}</Box>
  </Box>
);

export default RIModalSection;
